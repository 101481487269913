<template>
  <div class="item-Q5">
    <el-container>
      <el-header style="padding: 0" height="100%">
        <vheadere class="hidden-sm-and-down"></vheadere>
        <ivheaderae class="hidden-md-and-up"></ivheaderae>
      </el-header>
      <div class="main">
        <div class="main-top">
          <div class="w">
            <el-row>
              <el-col :xs="24" :sm="24" :md="12">
                <div class="fun-ava"><img :src="image" /></div>
              </el-col>
              <el-col :xs="24" :sm="24" :md="12" :offset="0">
                <p class="fun-tit">{{ funtit }}</p>
                <div class="fun-look-m">
                  <i></i>
                  <p>Look {{ collection }}</p>
                  <div class="m-show-map" v-show="mapshow"><div class="btn" @click="openmap()">Event map</div></div>
                </div>
                <div class="fun-time">
                  <i></i>
                  <p>{{ gettime(starttime.replace(/-/g, "/")) }}～{{ gettime(endtime.replace(/-/g, "/")) }}</p>
                </div>
                <div class="fun-site">
                  <i></i>
                  <p>{{ address }}</p>
                </div>
                <div class="btns top-btn">
                  <a class="btn1" @click="joinfun">Register</a>
                  <a class="btn2" @click="colfun()">{{ colbtn1 ? 'Favourite' : 'Save' }}</a>
                </div>
                <div class="fun-txs">
                  <div class="fun-tx"><img :src="zbftx" /></div>
                  <div class="ava-fra">
                    <p class="ava-name">{{ zbfname }}</p>
                    <button class="concern-btn" @click="followBtn()">{{ follow ? 'Followed' : 'Follow' }}</button>
                  </div>
                </div>
                <div class="ava-btns">
                  <button class="join-btn" @click="joinfun">Register</button>
                  <button class="collect-btn" @click="colfun()">
                    <i :class="colbtn1 ? 'act' : ''"></i>
                    <p>{{ colbtn1 ? 'Favourite' : 'Save' }}</p>
                  </button>
                </div>
              </el-col>
            </el-row>
            <div class="fun-look-share">
              <p class="look">{{ collection }}</p>
              <p class="share">Share with friends</p>
              <div class="social-share"></div>
            </div>
          </div>
        </div>
        <div class="main-mid">
          <div class="w">
            <el-row>
              <el-col :xs="24" :sm="24" :md="8" class="z-fra-m">
                <p class="fun-cnt c4">Event organizer</p>
                <div class="z-intor">
                  <div class="z-intor2">
                    <div class="intor-top">
                      <img :src="zbftx" />
                      <div class="intor-right">
                        <p class="intor-cnt2">{{ zbfname }}</p>
                      </div>
                    </div>
                    <p class="intor-des">{{ zbfcontent }}</p>
                    <ul class="intor-a">
                      <li v-for="(item, index) in zbffunlists" :key="index" v-if="index <= 2">
                        <a @click="gofun(item)">{{ item.TITLE }}</a>
                      </li>
                    </ul>
                  </div>
                  <div class="btns">
                    <a class="btn1" @click="followBtn()">{{ follow ? 'Followed' : 'Follow' }}</a>
                    <a class="btn2" @click="gopor()">View the organizer page</a>
                  </div>
                </div>
              </el-col>
              <el-col :xs="24" :sm="24" :md="15">
                <p class="fun-cnt">Event Details</p>
                <div class="fun-cnt2 ql-editor" v-html="content">
                  <!-- <p>
                    “Just Shenzhen
                    正深圳”全域体验线路横跨+罗湖区、福田区和南山区，通过8个探索地点（国贸大厦、华强北、莲花山公园、深圳音乐厅、平安金融中心、华侨城创意园、深圳人才公园、海上世界）和40个游戏任务，了解深圳地标及其周边的精彩故事和文化内涵，体验深圳的硬实力和软文化。
                  </p>
                  <img src="../../assets/images/Q/Q5/pic.png" /> -->
                </div>
                <p class="fun-cnt c2">Discussion</p>
                <textarea class="p-textarea" v-model="msginput"></textarea>
                <div class="sub"><button class="submit-btn" @click="savemsg()">Leave a message</button></div>
                <ul class="p-ul">
                  <li v-for="(item, index) in msglist" :key="index">
                    <div class="p-tx"><img :src="item.IMAGE" /></div>
                    <div class="p-right">
                      <p class="p-name">{{ item.NAME }}</p>
                      <p class="p-cnt">{{ item.MESSAGE }}</p>
                      <p class="zan-num" @click="dz(item.MAKEACTIVITYMX_ID, index)">
                        <span></span>
                        {{ item.SORT }} Like
                      </p>
                    </div>
                  </li>
                </ul>
              </el-col>
              <el-col :xs="24" :sm="24" :md="8" :offset="1" class="z-fra">
                <div class="z-intor">
                  <div class="z-intor2">
                    <div class="intor-top">
                      <img :src="zbftx" />
                      <div class="intor-right">
                        <p class="intor-cnt1">Event organizer</p>
                        <p class="intor-cnt2">{{ zbfname }}</p>
                      </div>
                    </div>
                    <p class="intor-des">{{ zbfcontent }}</p>
                    <ul class="intor-a">
                      <li v-for="(item, index) in zbffunlists" :key="index" v-if="index <= 2">
                        <a @click="gofun(item)">{{ item.TITLE }}</a>
                      </li>
                      <!-- <li><a @click="gofun(zbffunlists[1].id)">{{zbffunlists[1].title}}</a></li>
                      <li><a @click="gofun(zbffunlists[2].id)">{{zbffunlists[2].title}}</a></li> -->
                    </ul>
                  </div>
                  <a class="gopage" @click="gopor()">View the organizer page ></a>
                </div>
                <div v-show="mapshow">
                <p class="fun-cnt c3">Event map</p>
                <div class="v-map">
                  <!-- <el-amap class="amap-box" :vid="'amap-vue'" :center="center" :plugin="plugin"></el-amap> -->
                  <baidu-map class="map" :center="center" :zoom="zoom" @ready="handler" :scroll-wheel-zoom="true">
                    <bm-view style="width: 100%; height: 100%"></bm-view>
                    <bm-overlay pane="labelPane" @draw="draw" class="side-icon" :dragging="true"></bm-overlay>
                  </baidu-map>
                </div>
                </div>
              </el-col>
            </el-row>
          </div>
        </div>
        <div class="fun-swiper">
          <div class="w">
            <div class="main-funActive">
              <div class="buzz-funActive">
                <div class="funActive-title">
                  <img src="../../assets/images/Q/Q5/recommendEN.png" alt="" class="title-funactive" />
                  <img src="../../assets/images/Q/Q5/recommendEN.png" alt="" class="title-funactive-m" />
                </div>
                <div class="funActive-card">
                  <el-row>
                    <el-carousel trigger="hover" indicator-position="outside" :height="funCurHeight + 1 + 'px'" ref="elswiper">
                      <el-carousel-item v-for="(arr, index) in recActivity" :key="index">
                        <h3 class="small" ref="funCur">
                          <el-col :xs="24" :sm="8" :md="8" v-for="(item, index1) in arr" :key="index1">
                            <div class="fcard-item">
                              <el-col :xs="12" :sm="24" :md="24">
                                <div class="over-hidden"><img class="item-img" @load="actLoad" width="100%" :src="item.ONE" alt="" @click="gofun(item)" /></div>
                              </el-col>
                              <el-col :xs="12" :sm="24" :md="24">
                                <div class="fcard-content" @click="gofun(item)">
                                  <div class="fcard-txt">
                                    <p>{{ item.TITLE }}</p>
                                  </div>
                                  <div class="fcard-icon">
                                    <p>
                                      <i class="el-icon-time"></i>
                                      {{ gettime(item.STARTTIME) }}
                                    </p>
                                    <p>
                                      <i class="el-icon-location"></i>
                                      {{ item.ADDRESS }}
                                    </p>
                                  </div>
                                  <div class="fcard-foot clearfloat">
                                    <img :src="item.images" alt="" />
                                    <span class="display-none">{{ item.CNNAME }}</span>
                                  </div>
                                </div>
                              </el-col>
                            </div>
                          </el-col>
                        </h3>
                      </el-carousel-item>
                    </el-carousel>
                  </el-row>
                </div>
              </div>
            </div>
          </div>
          <!-- <div class="swipe-m-btn">
            <a class="btn1">创建我的活动</a>
            <a class="btn2">查看All活动</a>
          </div> -->
        </div>
        <el-footer>
          <vfootere class="hidden-sm-and-down"></vfootere>
          <ivfootere class="hidden-md-and-up"></ivfootere>
        </el-footer>
        <div class="m-mapshow" :style="`left:${mapm}`">
          <div class="back">
            <p class="tit">Event map</p>
            <button class="back-btn" @click="mapm = '-999999999px'">Back</button>
          </div>
          <!-- <el-amap class="amap-box" :vid="'amap-vue-m'" :center="center" :plugin="plugin"></el-amap> -->
          <baidu-map class="map" :center="center" :zoom="zoom" @ready="handler" :scroll-wheel-zoom="true">
            <bm-view style="width: 100%; height: 100%"></bm-view>
            <bm-overlay pane="labelPane" @draw="draw" class="side-icon"></bm-overlay>
          </baidu-map>
        </div>

        <div class="mask" v-show="mask"></div>
        <div class="tc-fra" v-show="tc1 || tc2 || tc3 || tc4">
          <div class="tc sign-form" v-show="tc1">
            <p class="tit">Registration completed</p>
            <p class="der">
              You have successfully registered for this event.
              <br />
              You can also find the admission code on "My Personal Page".
            </p>
            <!-- <img class="qr" src="../../assets/images/Q/Q5/qr.png" /> -->
            <p class="code-num">
              <span>Admission code will be sent to email, please check it in time.</span>
            </p>
            <div class="btns">
              <a class="btn1" @click="colortc('tc1')">Close</a>
              <a class="btn2" @click="gouser()">Enter my personal page</a>
            </div>
          </div>

          <div class="tc sign-fun" v-show="tc2">
            <p class="tit">Event Registration</p>
            <p class="der">Please fill out the following information as the requirements.</p>
            <div class="input-form">
              <!-- <p class="i-p">
              <span>*</span>
              姓名：
            </p>
            <input class="input" />
            <p class="i-p">
              <span>*</span>
              职业：
            </p>
            <input class="input" />
            <p class="i-p">
              <span>*</span>
              年龄：
            </p>
            <input class="input" /> -->
              <el-form :rules="z1formrule3" :model="z1form3" ref="z1form3" label-width="100%" label-position="left" class="z1-form1">
                <el-form-item label="Name" prop="name"><el-input v-model="z1form3.name"></el-input></el-form-item>
                <el-form-item label="Email" prop="email"><el-input v-model="z1form3.email"></el-input></el-form-item>
<!--                <el-form-item label="Phone" prop="phone"><el-input v-model="z1form3.phone" type="number"></el-input></el-form-item>-->
              </el-form>
              <!--  -->
              <div class="moreform" v-if="moreform.length != 0">
                <div v-for="(item, index) in moreform" :key="index">
                  <div v-if="item.type == 'text'">
                    <p class="i-p">
                      <span v-if="item.mustChecked">*</span>
                      {{ item.title }}：
                    </p>
                    <el-input :placeholder="item.tips" v-model="item.value"></el-input>
                  </div>
                  <div v-if="item.type == 'date'">
                    <p class="i-p">
                      <span v-if="item.mustChecked">*</span>
                      {{ item.title }}：
                    </p>
                    <el-date-picker v-model="item.value" type="date" :placeholder="item.tips"></el-date-picker>
                  </div>
                  <div v-if="item.type == 'textarea'">
                    <p class="i-p">
                      <span v-if="item.mustChecked">*</span>
                      {{ item.title }}：
                    </p>
                    <el-input type="textarea" :rows="2" :placeholder="item.tips" v-model="item.value"></el-input>
                  </div>
                  <div v-if="item.type == 'checkbox'">
                    <p class="i-p">
                      <span v-if="item.mustChecked">*</span>
                      {{ item.title }}：
                    </p>
                    <el-checkbox-group v-model="item.value"><el-checkbox :label="sel.txt" v-for="(sel, index) in item.selItem" :key="index"></el-checkbox></el-checkbox-group>
                  </div>
                  <div v-if="item.type == 'number'">
                    <p class="i-p">
                      <span v-if="item.mustChecked">*</span>
                      {{ item.title }}：
                    </p>
                    <el-input type="number" :placeholder="item.tips" v-model="item.value"></el-input>
                  </div>
                  <div v-if="item.type == 'email'">
                    <p class="i-p">
                      <span v-if="item.mustChecked">*</span>
                      {{ item.title }}：
                    </p>
                    <el-input :placeholder="item.tips" v-model="item.value"></el-input>
                  </div>
                  <div v-if="item.type == 'radio'">
                    <p class="i-p">
                      <span v-if="item.mustChecked">*</span>
                      {{ item.title }}：
                    </p>
                    <el-radio-group v-model="item.value">
                      <template v-for="(item2, index) in item.selItem">
                        <el-radio :label="index" :key="index">{{ item2.txt }}</el-radio>
                      </template>
                    </el-radio-group>
                  </div>
                  <div v-if="item.type == 'select'">
                    <p class="i-p">
                      <span v-if="item.mustChecked">*</span>
                      {{ item.title }}：
                    </p>
                    <el-select v-model="item.value" :placeholder="item.tips">
                      <el-option v-for="item in item.selItem" :key="item.txt" :label="item.txt" :value="item.txt"></el-option>
                    </el-select>
                  </div>
                </div>
              </div>
              <!--  -->
            </div>
            <div class="btns">
              <a class="btn1" @click="colortc('tc2')">Cancel</a>
              <a class="btn2" @click="enlist()">Sign up</a>
            </div>
          </div>

          <div class="tc sign-prov-suc" v-show="tc3">
            <p class="tit">Successful</p>
            <div class="prov-cnt">
              <img src="../../assets/images/Q/Q5/status-success.png" />
              <p>
                恭喜您成功注册成为CityPlus平台会员，
                <br />
                账号密码已发送到您的验证邮箱/手机，
                <br />
                您可通过账号密码登录CityPlus了解活动详情。
              </p>
            </div>
            <div class="btns"><a class="btn1">下一步</a></div>
          </div>

          <div class="tc sign-prov" v-show="tc4">
            <p class="tit">验证身份</p>
            <div class="check-box">
              <div class="check-d" @click="changecheck(1)">
                <div :class="clickcur == 1 ? 'check-i select' : 'check-i'"></div>
                <p class="check-p">验证邮箱</p>
              </div>
              <div class="check-d" @click="changecheck(2)">
                <div :class="clickcur == 2 ? 'check-i select' : 'check-i'"></div>
                <p class="check-p">验证手机</p>
              </div>
            </div>
            <div class="input-form" v-show="clickcur == 1">
              <el-form :rules="z1formrule1" :model="z1form1" ref="z1form1" label-width="100%" label-position="left" class="z1-form1">
                <el-form-item label="请输入邮箱" prop="email">
                  <el-input v-model="z1form1.email"></el-input>
                  <div class="sub-code" @click="getemailcode()" :disabled="getcode1.disablebtn" :class="getcode1.disablebtn ? 'disablebtn' : ''">{{ getcode1.emailgetcode }}</div>
                </el-form-item>
                <el-form-item label="输入验证码" prop="code"><el-input v-model="z1form1.code"></el-input></el-form-item>
              </el-form>
            </div>
            <div class="input-form" v-show="clickcur == 2">
              <el-form :rules="z1formrule2" :model="z1form2" ref="z1form2" label-width="100%" label-position="left" class="z1-form1">
                <el-form-item label="Please enter phone number" prop="phone" class="poneinput">
                  <el-input v-model="z1form2.areacode" type="number" class="areacode">
                    <template slot="prepend">
                      +
                    </template>
                  </el-input>
                  <el-input v-model="z1form2.phone" type="number" class="phone"></el-input>
                  <div class="sub-code" @click="getphonecode()" :disabled="getcode2.disablebtn" :class="getcode2.disablebtn ? 'disablebtn' : ''">{{ getcode2.phonegetcode }}</div>
                </el-form-item>
                <el-form-item label="输入验证码" prop="code"><el-input v-model="z1form2.code"></el-input></el-form-item>
              </el-form>
            </div>
            <el-checkbox v-model="checked" class="agree">
              I have read
              <a> CityPlus User Registration Agreement</a>
            </el-checkbox>
            <div class="btns">
              <a class="btn1" @click="colortc('tc3')">Cancel my registration</a>
              <a class="btn2" @click="checkform()">验证成为CityPlus会员</a>
            </div>
          </div>
        </div>
      </div>
    </el-container>
  </div>
</template>

<script>
// import VueAMap from 'vue-amap';
// // Vue.use(VueAMap);
// VueAMap.initAMapApiLoader({
//   key: 'ef5845fb1b0d7837f2ddb9567e72c49a',
//   plugin: ['AMap.Autocomplete', 'AMap.PlaceSearch', 'AMap.Scale', 'AMap.OverView', 'AMap.ToolBar', 'AMap.MapType', 'AMap.PolyEditor', 'AMap.CircleEditor', 'AMap.Geolocation'],
//   // 默认高德 sdk 版本为 1.4.4
//   v: '1.4.4'
// });
import {
  activity,
  Leavemsg,
  getUserData,
  listly,
  dz,
  cleckcol,
  colfun,
  delcolfun,
  addCollect,
  delCollect,
  checkCollect,
  q1Activity,
  initiatelist,
  getfuncolnum,
  getfunlooknum
} from '../../api'
import { testphone, testemail } from '../../api-en'
import qs from 'qs'
export default {
  data () {
    const _this = this
    var checkemail = (rule, value, callback) => {
      var reg = /^([a-zA-Z]|[0-9])(\w|\-)+@[a-zA-Z0-9]+\.([a-zA-Z]{2,4})$/
      if (reg.test(this.z1form1.email)) {
        callback()
      } else {
        callback(new Error('Incorrect mailbox format'))
      }
    }
    var checkemail2 = (rule, value, callback) => {
      var reg = /^([a-zA-Z]|[0-9])(\w|\-)+@[a-zA-Z0-9]+\.([a-zA-Z]{2,4})$/
      if (reg.test(this.z1form3.email)) {
        callback()
      } else {
        callback(new Error('Incorrect mailbox format'))
      }
    }
    var checknewpass = (rule, value, callback) => {
      var regx = /^(?!([a-zA-Z]+|\d+)$)[a-zA-Z\d]{6,20}$/
      if (this.z1form1.pass.match(regx) == null) {
        callback(new Error('Your password needs to be a combination of numbers and letters'))
      } else {
        callback()
      }
    }
    var checknewpass2 = (rule, value, callback) => {
      var regx = /^(?!([a-zA-Z]+|\d+)$)[a-zA-Z\d]{6,20}$/
      if (this.z1form1.pass != this.z1form1.checkpass) {
        callback(new Error('Please enter the same password again '))
      } else {
        if (this.z1form1.pass.match(regx) == null) {
          callback(new Error('Your password needs to be a combination of numbers and letters'))
        } else {
          callback()
        }
      }
    }
    var checknewpass3 = (rule, value, callback) => {
      var regx = /^(?!([a-zA-Z]+|\d+)$)[a-zA-Z\d]{6,20}$/
      if (this.z1form2.pass.match(regx) == null) {
        callback(new Error('Your password needs to be a combination of numbers and letters'))
      } else {
        callback()
      }
    }
    var checknewpass4 = (rule, value, callback) => {
      var regx = /^(?!([a-zA-Z]+|\d+)$)[a-zA-Z\d]{6,20}$/
      if (this.z1form1.pass != this.z1form1.checkpass) {
        callback(new Error('Please enter the same password again '))
      } else {
        if (this.z1form2.pass.match(regx) == null) {
          callback(new Error('Your password needs to be a combination of numbers and letters'))
        } else {
          callback()
        }
      }
    }
    return {
      funtit: '',
      starttime: '',
      starttime2: '',
      endtime: '',
      endtime2: '',
      content: '',
      cityaddress: '',
      address: '',
      image: '',
      collection: '',
      msginput: '',
      state:'',
      infocleck: '',
      userInfo: {
        name: '',
        tx: ''
      },
      zbftx: '',
      zbfname: '',
      zbfcontent: '',
      colbtn1: false,
      follow: true,
      zbfid: '',
      moreform: '',
      recActivity: [],
      funCurHeight: 0,
      zbffunlists: [],
      funcolnum: 0,

      mapm: '-999999999px',
      showside: false,
      checked: true,
      clickcur: 1,
      mask: false,
      tc1: false,
      tc2: false,
      tc3: false,
      tc4: false,
      center: { lng: 0, lat: 0 },
      zoom: 15,
      mapshow: true,

      z1form1: {
        email: '',
        code: '',
        pass: '',
        checkpass: ''
      },
      z1form2: {
        areacode: '86',
        phone: '',
        code: '',
        pass: '',
        checkpass: '',
        getcode: ''
      },
      z1form3: {
        name: '',
        email: '',
        phone: ''
      },
      z1formrule1: {
        email: [{ type: 'email', required: true, message: '请输入正确的邮箱地址', trigger: ['blur', 'change'] }],
        code: [{ required: true, message: 'Please enter the security code', trigger: 'blur' }],
        pass: [{ required: true, validator: checknewpass, trigger: 'blur' }, { min: 6, max: 24, message: '密码为6至24位数字+英文组合' }],
        checkpass: [{ required: true, validator: checknewpass2, trigger: 'blur' }, { min: 6, max: 24, message: '密码为6至24位数字+英文组合' }]
      },
      z1formrule2: {
        phone: [{ required: true, message: 'Please enter phone number', trigger: 'blur' }],
        code: [{ required: true, message: 'Please enter the security code', trigger: 'blur' }],
        pass: [{ required: true, validator: checknewpass3, trigger: 'blur' }, { min: 6, max: 24, message: '密码为6至24位数字+英文组合' }],
        checkpass: [{ required: true, validator: checknewpass4, trigger: 'blur' }, { min: 6, max: 24, message: '密码为6至24位数字+英文组合' }]
      },
      z1formrule3: {
        name: [{ required: true, message: 'Please enter Name', trigger: 'blur' }],
        email: [
          { required: true, message: 'Please enter the Email Address', trigger: 'blur' },
          {
            required: true,
            validator: checkemail2,
            trigger: 'change'
          }
        ]
        // phone: [{ required: true, message: 'Please enter phone number', trigger: 'blur' }]
      },
      getcode1: {
        emailgetcode: 'Get verification code',
        disablebtn: false,
        timer: null,
        count: ''
      },
      getcode2: {
        phonegetcode: 'Get verification code',
        disablebtn: false,
        timer: null,
        count: ''
      },
      msglist: []
    }
  },
  inject: ['reload'],
  methods: {
    html_decode (str) {
      var s = ''
      if (str.length == 0) {
        return ''
      }
      s = str.replace(/&amp;/g, '&')
      s = s.replace(/&lt;/g, '<')
      s = s.replace(/&gt;/g, '>')
      s = s.replace(/&nbsp;/g, ' ')
      // eslint-disable-next-line no-useless-escape
      s = s.replace(/&#39;/g, "\'")
      s = s.replace(/&quot;/g, '"')
      s = s.replace(/<br\/>/g, '\n')
      return s
    },
    changecheck (i) {
      this.clickcur = i
    },
    openmap () {
      this.mapm = '0'
    },
    handler ({ BMap, map }) {
      let userInfo = JSON.parse(localStorage.getItem('userInfo'))
      // if (userInfo == undefined || userInfo == null) {
      //   this.$confirm('To log in, please click the button below', '提示', {
      //     confirmButtonText: '确定',
      //     type: 'warning',
      //     center: true,
      //     showCancelButton: false,
      //     showClose: false,
      //     closeOnPressEscape: false
      //   }).then(() => {
      //     this.$router.push('/LoginEN')
      //   })
      // } else {
        let isid = this.$route.query.id
        let isuid = this.$route.query.uid
        // let isid = 'be14b58a645f4c748fafb9d0fd0008f7';
        let list = {
          MAKEACTIVITY_ID: isid,
          USERID: isuid
        }
        q1Activity().then(res => {
          // let result = []
          // for (let i = 0; i < res.data.length; i += 3) {
          //   if (res.data[i].RECOMMEND == 'true') {
          //     result.push(res.data.slice(i, i + 3))
          //   }
          // }
          // this.recActivity = result
          let result = []
          let result1 = []
          for (let i = 0; i < res.data.length; i++) {
            if (res.data[i].RECOMMEND == 'true') {
              if (res.data[i].是否显示 == 'true') {
                result.push(res.data[i])
              }
            }
          }
          for (let i = 0; i < result.length; i += 3) {
            result1.push(result.slice(i, i + 3))
          }
          this.recActivity = result1
        })
        activity(qs.stringify(list)).then(res => {
          this.funtit = res.data[0].TITLE
          var config = {
            url: window.location.href, // 网址，默认使用 window.location.href
            source: '', // 来源（QQ空间会用到）, 默认读取head标签：<meta name="site" content="http://overtrue" />
            title: 'CityPlusActivity-' + res.data[0].TITLE, // 标题，默认读取 document.title 或者 <meta name="title" content="share.js" />
            description: 'Please pay attention to the CityPlus Platform for Interesting Activities and publish wonderful activities.', // 描述, 默认读取head标签：<meta name="description" content="PHP弱类型的实现原理分析" />
            image: '/FH-WEB/uploadFiles/uploadImgs/20190829/adf551cfdd994f729a0f53e248d9b76c.jpg', // 图片, 默认取网页中第一个img标签
            sites: ['weibo', 'qq', 'wechat'], // 启用的站点
            // disabled: ['google', 'facebook', 'twitter'], // 禁用的站点
            wechatQrcodeTitle: 'Wechat sweep：share', // 微信二维码提示文字
            wechatQrcodeHelper: ''
          }
          window.socialShare('.social-share', config)
          this.starttime = res.data[0].STARTTIME.replace(/-/g, '/')
          this.starttime2 = res.data[0].STARTTIME2
          this.endtime = res.data[0].ENDTIME.replace(/-/g, '/')
          this.endtime2 = res.data[0].ENDTIME2
          this.address = res.data[0].ADDRESS
          this.image = res.data[0].ONE
          this.collection = res.data[0].COLLECTION || 0
          this.content = this.html_decode(res.data[0].CONTENT)
          this.zbftx = res.data[0].images
          this.zbfname = res.data[0].ENNAME == ''? res.data[0].CNNAME : res.data[0].ENNAME
          this.zbfid = res.data[0].SPONSOR_ID
          this.state = res.data[0].STATE
          this.infocleck = res.data[0].INFOCHECK
          let id = this.zbfid

          initiatelist(id).then(res => {
            // this.zbffunlists = res.data
            this.zbfcontent = res.data[0].简介
            let result = []
            for(let i = 0, len = res.data.length; i<len; i++){
              if(res.data[i].是否显示 == 'true'){
                result.push(res.data[i])
              }
            }
            this.zbffunlists = result
          })
          // getfuncolnum(isid).then(res => {
          //   this.funcolnum = res.data[0]['count(ACTIVITYID)']
          // })
          checkCollect(userInfo.userId, this.zbfid).then(res => {
            if (res.data[0]['count(USERID)'] != 0) {
              this.follow = true
            } else {
              this.follow = false
            }
          })
          this.moreform = JSON.parse(res.data[0].THREE).typeData
          let len = this.moreform.length
          for (let i = 0; i < len; i++) {
            if (
              this.moreform[i].type == 'text' ||
              this.moreform[i].type == 'date' ||
              this.moreform[i].type == 'textarea' ||
              this.moreform[i].type == 'number' ||
              this.moreform[i].type == 'email'
            ) {
              // this.moreform[i].value = ''
              this.$set(this.moreform[i], 'value', '')
            }
            if (this.moreform[i].type == 'checkbox') {
              // this.moreform[i].value = [this.moreform[i].selItem[0].txt]
              this.$set(this.moreform[i], 'value', [this.moreform[i].selItem[0].txt])
            }
            if (this.moreform[i].type == 'radio') {
              // this.moreform[i].value = 0
              this.$set(this.moreform[i], 'value', 0)
            }
            if (this.moreform[i].type == 'select') {
              this.$set(this.moreform[i], 'value', this.moreform[i].selItem[0].txt)
            }
          }
          this.cityaddress = JSON.parse(res.data[0].CITYADDRESS)
          this.center.lng = this.cityaddress.lng
          this.center.lat = this.cityaddress.lat
          if(JSON.parse(res.data[0].CITYADDRESS).map != undefined){
            this.mapshow = false
          }
        })
        let data = {
          SPONSOR_ID: userInfo.userId
        }
        getUserData(qs.stringify(data)).then(res => {
          this.userInfo.name = res.data.new.NAME || 'user'
          this.userInfo.tx = res.data.new.IMAGE || '/FH-WEB/uploadFiles/uploadImgs/20190827/4503f3a7a45547c5a4c3d55107d2976d.png'
        })
        listly(isid).then(res => {
          this.msglist = res.data
        })
        cleckcol(userInfo.userId, isid).then(res => {
          if (res.data[0]['count(USERID)'] != 0) {
            this.colbtn1 = true
          } else {
            this.colbtn1 = false
          }
        })
      // }
    },
    draw ({ el, BMap, map }) {
      const pixel = map.pointToOverlayPixel(new BMap.Point(this.cityaddress.lng, this.cityaddress.lat))
      el.style.left = pixel.x + 'px'
      el.style.top = pixel.y + 'px'
    },
    gettime (timeDate) {
      var date = new Date(timeDate.replace(/-/g, '/'))
      var year = date.getFullYear()
      var month = date.getMonth() + 1
      var day = date.getDate()
      var hour = date.getHours() < 10 ? '0' + date.getHours() : date.getHours()
      var minute = date.getMinutes() < 10 ? '0' + date.getMinutes() : date.getMinutes()
      var second = date.getSeconds() < 10 ? '0' + date.getSeconds() : date.getSeconds()
      var currentTime = year + '-' + month + '-' + day + ' ' + hour + ':' + minute
      return currentTime
    },
    getemailcode () {
      this.$refs.z1form1.validateField('email', valid => {
        if (this.z1form1.email == '') {
          this.$message({
            showClose: true,
            message: 'Please enter the Email Address',
            type: 'error',
            center: true
          })
          return false
        } else if (valid) {
          this.$message({
            showClose: true,
            message: 'Incorrect mailbox format',
            type: 'error',
            center: true
          })
          return false
        } else {
          this.getcode1.emailgetcode = 'Get verification code'
          const TIME_COUNT = 60
          if (!this.getcode1.timer) {
            this.getcode1.count = TIME_COUNT
            this.getcode1.emailgetcode = 'Get verification code'
            this.getcode1.timer = setInterval(() => {
              if (this.getcode1.count > 0 && this.getcode1.count <= TIME_COUNT) {
                this.getcode1.count--
                this.getcode1.emailgetcode = `${this.getcode1.count}s`
                this.getcode1.disablebtn = true
              } else {
                this.getcode1.emailgetcode = 'Get verification code'
                this.getcode1.disablebtn = false
                clearInterval(this.getcode1.timer)
                this.getcode1.timer = null
              }
            }, 1000)
          }
          let em = this.z1form1.email
          testemail(em).then(res => {
            if (typeof res.data == 'string') {
              this.$message({
                showClose: true,
                message: 'Mail sent successfully',
                type: 'success',
                center: true
              })
              this.z1form1.getcode = res.data
            } else {
              this.$message({
                showClose: true,
                message: 'Mail failed to send',
                type: 'error',
                center: true
              })
            }
          })
        }
      })
    },
    getphonecode () {
      this.$refs.z1form2.validateField('phone', valid => {
        if (this.z1form1.phone == '') {
          this.$message({
            showClose: true,
            message: 'Please enter phone number',
            type: 'error',
            center: true
          })
          return false
        } else if (valid) {
          this.$message({
            showClose: true,
            message: 'Phone format error',
            type: 'error',
            center: true
          })
          return false
        } else {
          this.getcode2.phonegetcode = 'Get verification code'
          const TIME_COUNT = 60
          if (!this.getcode2.timer) {
            this.getcode2.count = TIME_COUNT
            this.getcode2.phonegetcode = 'Get verification code'
            this.getcode2.timer = setInterval(() => {
              if (this.getcode2.count > 0 && this.getcode2.count <= TIME_COUNT) {
                this.getcode2.count--
                this.getcode2.phonegetcode = `${this.getcode2.count}s`
                this.getcode2.disablebtn = true
              } else {
                this.getcode2.phonegetcode = 'Get verification code'
                this.getcode2.disablebtn = false
                clearInterval(this.getcode2.timer)
                this.getcode2.timer = null
              }
            }, 1000)
          }
          let num = this.z1form2.areacode == '86' ? this.z1form2.phone : this.z1form2.areacode + this.z1form2.phone
          testphone(num).then(res => {
            if (!res.data) {
              this.$message({
                showClose: true,
                message: 'Incorrect mobile phone format or limit of  times of sending security code  reached',
                type: 'error',
                center: true
              })
              return false
            } else if (typeof res.data == 'string') {
              this.$message({
                showClose: true,
                message: 'SMS security code has been sent',
                type: 'success',
                center: true
              })
              this.z1form2.getcode = res.data
            }
          })
        }
      })
    },
    checkform () {
      let formid, form
      if (this.clickcur == 1) {
        formid = 'z1form1'
        form = this.z1form1
      } else {
        formid = 'z1form2'
        form = this.z1form2
      }
      this.$refs[formid].validate(valid => {
        if (valid) {
          console.log('success')
          if (this.getmd5(form.code) == form.getcode) {
            console.log('验证正确')
            this.tc4 = false
            this.tc3 = true
          } else {
            console.log('验证错误')
            this.$message({
              showClose: true,
              message: 'Wrong security code! ',
              type: 'error',
              center: true
            })
            return false
          }
        }
      })
    },
    getmd5 (code) {
      return this.$md5(code)
    },
    joinfun () {
      let userInfo = JSON.parse(localStorage.getItem('userInfo'))
      if(userInfo.userName == 'yk'){
        this.$confirm('To log in, please click the button below', 'Tips', {
          confirmButtonText: 'Confirm',
          type: 'warning',
          center: true,
          showCancelButton: false,
          showClose: false,
          closeOnPressEscape: false
        }).then(() => {
          this.$router.push('/LoginEN')
        })
      } else {
        let acstarttime = new Date((this.starttime2)).valueOf()
        let acendtime = new Date((this.endtime2)).valueOf()
        let nowtime = (new Date()).valueOf()
        if(this.infocleck == 'true'){
          if(acendtime < nowtime) {
            this.$message({
              showClose: true,
              message: 'The event has ended.',
              type: 'error',
              center: true
            })
          } else {
            this.mask = true
            this.tc2 = true
          }
        } else {
          if(acendtime < nowtime) {
            this.$message({
              showClose: true,
              message: 'The event has ended.',
              type: 'error',
              center: true
            })
          } else if( acstarttime > nowtime ){
            this.$message({
              showClose: true,
              message: 'The event registration time yet to come.',
              type: 'error',
              center: true
            })
          } else {
            this.mask = true
            this.tc2 = true
          }
        }
      }
    },
    enlist () {
      this.$refs['z1form3'].validate(valid => {
        if (valid) {
          if (this.moreform.length != 0) {
            for (let i = 0, len = this.moreform.length; i < len; i++) {
              if (
                this.moreform[i].type == 'text' ||
                this.moreform[i].type == 'date' ||
                this.moreform[i].type == 'textarea' ||
                this.moreform[i].type == 'number' ||
                this.moreform[i].type == 'email'
              ) {
                if (this.moreform[i].mustChecked) {
                  if (this.moreform[i].value == '') {
                    this.$message({
                      type: 'error',
                      message: 'Please fill in the required fields.',
                      center: true
                    })
                    return false
                  }
                }
              }
              if (this.moreform[i].type == 'checkbox') {
                if (this.moreform[i].mustChecked) {
                  if (this.moreform[i].value.length == 0) {
                    this.$message({
                      type: 'error',
                      message: 'Please fill in the required fields.',
                      center: true
                    })
                    return false
                  }
                }
              }
            }
            let morelist = []
            for (let i = 0, len = this.moreform.length; i < len; i++) {
              if (this.moreform[i].type == 'date') {
                let datas = {
                  title: this.moreform[i].title,
                  value: this.gettime(this.moreform[i].value)
                }
                morelist.push(datas)
              } else if (this.moreform[i].type == 'radio') {
                let datas = {
                  title: this.moreform[i].title,
                  value: this.moreform[i].selItem[this.moreform[i].value].txt
                }
                morelist.push(datas)
              } else if (this.moreform[i].type == 'checkbox') {
                let str = ''
                if (this.moreform[i].value.length != 0) {
                  for (let _i = 0, len = this.moreform[i].value.length; _i < len; _i++) {
                    str += this.moreform[i].value[_i]
                  }
                  let datas = {
                    title: this.moreform[i].title,
                    value: str
                  }
                  morelist.push(datas)
                } else {
                  let datas = {
                    title: this.moreform[i].title,
                    value: ''
                  }
                  morelist.push(datas)
                }
              } else {
                let datas = {
                  title: this.moreform[i].title,
                  value: this.moreform[i].value
                }
                morelist.push(datas)
              }
              // morelist.push(datas)
            }
            let isid = this.$route.query.id
            let userInfo = JSON.parse(localStorage.getItem('userInfo'))
            let list = {
              NAME: this.z1form3.name,
              // CELLPHONE: this.z1form3.phone,
              language: 'en',
              EMAIL: this.z1form3.email,
              MAKEACTIVITY_ID: isid,
              NIGHT: JSON.stringify(morelist),
              USERID: userInfo.userId
            }
            Leavemsg(qs.stringify(list)).then(res => {
              if(res.data.resultemail == 'YES'){
                this.$message({
                  type: 'error',
                  message: 'This Email Address has been registered for the event. Don’t double sign up!',
                  center: true
                })
              } else {
                if (res.data.result == 'ok') {
                  this.$message({
                    type: 'success',
                    message: 'Complete！',
                    center: true
                  })
                  this.tc2 = false
                  this.tc1 = true
                } else {
                  this.$message({
                    type: 'error',
                    message: 'Error,please refresh the page and try again.',
                    center: true
                  })
                }
              }
            })
          } else {
            let isid = this.$route.query.id
            let userInfo = JSON.parse(localStorage.getItem('userInfo'))
            let list = {
              NAME: this.z1form3.name,
              language: 'en',
              // CELLPHONE: this.z1form3.phone,
              EMAIL: this.z1form3.email,
              MAKEACTIVITY_ID: isid,
              NIGHT: '',
              USERID: userInfo.userId
            }
            Leavemsg(qs.stringify(list)).then(res => {
              if(res.data.resultemail == 'YES'){
                this.$message({
                  type: 'error',
                  message: 'This Email Address has been registered for the event. Don’t double sign up!',
                  center: true
                })
              } else {
                if (res.data.result == 'ok') {
                  this.$message({
                    type: 'success',
                    message: 'Complete！',
                    center: true
                  })
                  this.tc2 = false
                  this.tc1 = true
                } else {
                  this.$message({
                    type: 'error',
                    message: 'Error,please refresh the page and try again.',
                    center: true
                  })
                }
              }
            })
          }
        }
      })
    },
    colortc (tcname) {
      this.mask = false
      this[tcname] = false
    },
    savemsg () {
      let userInfo = JSON.parse(localStorage.getItem('userInfo'))
      if(userInfo.userName == 'yk'){
        this.$confirm('To log in, please click the button below', 'Tips', {
          confirmButtonText: 'Confirm',
          type: 'warning',
          center: true,
          showCancelButton: false,
          showClose: false,
          closeOnPressEscape: false
        }).then(() => {
          this.$router.push('/LoginEN')
        })
      } else {
        if (this.msginput == '') {
          this.$message({
            message: 'Please enter a message.',
            type: 'warning',
            center: true
          })
          return false
        } else {
          let datas = {
            MESSAGE: this.msginput,
            language: 'en',
            MAKEACTIVITY_ID: this.$route.query.id,
            USERID: JSON.parse(localStorage.getItem('userInfo')).userId,
            NAME: this.userInfo.name,
            IMAGE: this.userInfo.tx,
            SORT: 0
          }
          Leavemsg(qs.stringify(datas)).then(res => {
            if (res.data.result == 'ok') {
              this.$message({
                message: 'Success！',
                type: 'success',
                center: true
              })
              this.reload()
            } else {
              this.$message({
                message: 'Network Error, please refresh the page and try again.',
                type: 'error',
                center: true
              })
              return false
            }
          })
        }
      }
    },
    dz (id, i) {
      let userInfo = JSON.parse(localStorage.getItem('userInfo'))
      if(userInfo.userName == 'yk'){
        this.$confirm('To log in, please click the button below', 'Tips', {
          confirmButtonText: 'Confirm',
          type: 'warning',
          center: true,
          showCancelButton: false,
          showClose: false,
          closeOnPressEscape: false
        }).then(() => {
          this.$router.push('/LoginEN')
        })
      } else {
        let funid = this.$route.query.id
        if (cookies.read(String(funid + '-' + id)) == 'voted') {
          this.$message({
            message: 'You’ve thumbed today. Please thumb 24h later.',
            center: true
          })
          return false
        } else {
          let list = {
            MAKEACTIVITYMX_ID: id
          }
          dz(qs.stringify(list)).then(res => {
            if (res.data == 'ok') {
              cookies.set(funid + '-' + id, 'voted', 1)
              this.reload()
            }
          })
        }
      }
    },
    colfun () {
      let userInfo = JSON.parse(localStorage.getItem('userInfo'))
      if(userInfo.userName == 'yk'){
        this.$confirm('To log in, please click the button below', 'Tips', {
          confirmButtonText: 'Confirm',
          type: 'warning',
          center: true,
          showCancelButton: false,
          showClose: false,
          closeOnPressEscape: false
        }).then(() => {
          this.$router.push('/LoginEN')
        })
      } else {
        let list = {
          ACTIVITYID: this.$route.query.id,
          USERID: userInfo.userId
        }
        if (this.colbtn1) {
          delcolfun(qs.stringify(list)).then(res => {
            if (res.data.result == 'ok') {
              this.colbtn1 = false
            }
          })
        } else {
          colfun(qs.stringify(list)).then(res => {
            if (res.data.result == 'ok') {
              this.colbtn1 = true
            }
          })
        }
      }
    },
    followBtn () {
      let userInfo = JSON.parse(localStorage.getItem('userInfo'))
      if(userInfo.userName == 'yk'){
        this.$confirm('To log in, please click the button below', 'Tips', {
          confirmButtonText: 'Confirm',
          type: 'warning',
          center: true,
          showCancelButton: false,
          showClose: false,
          closeOnPressEscape: false
        }).then(() => {
          this.$router.push('/LoginEN')
        })
      } else {
        let data = {
          USERID: userInfo.userId,
          SPONSORID: this.zbfid
        }
        if (this.follow) {
          delCollect(qs.stringify(data)).then(res => {
            if (res.data.result === 'ok') {
              this.follow = false
            }
          })
        } else {
          addCollect(qs.stringify(data)).then(res => {
            if (res.data.result === 'ok') {
              this.follow = true
            }
          })
        }
      }
    },
    actLoad () {
      this.$nextTick(() => {
        this.funCurHeight = this.$refs.funCur[0].clientHeight
      })
    },
    gouser () {
      this.$router.push({
        name: 'cityGe'
      })
    },
    gofun (item) {
      let id = item.MAKEACTIVITY_ID
      let uid = item.USERID
      this.$router.push({
        name: 'cityQ5e',
        query: {
          id: id,
          uid: uid
        }
      })
      this.reload()
    },
    gopor () {
      this.$router.push({
        name: 'cityQ4e',
        query: {
          id: this.zbfid
        }
      })
    }
  },
  mounted () {
    let isid = this.$route.query.id
    let datas = {
      MAKEACTIVITY_ID: isid
    }
    getfunlooknum(qs.stringify(datas)).then(res => {})
    this.$nextTick(() => {
      setTimeout(() => {
        this.funCurHeight = this.$refs.funCur[0].clientHeight
      }, 200)
    })
  },
  watch: {
    moreform (newValue, oldValue) {
      // console.log(newValue)
    }
  }
}

var cookies = {
  read: function (d) {
    var b = '; ' + document.cookie + '; '
    var a = b.indexOf('; ' + d + '=')
    if (a != -1) {
      var c = b.substring(a + d.length + 3, b.length)
      return unescape(c.substring(0, c.indexOf('; ')))
    } else {
      return ''
    }
  },
  set: function (d, g, b) {
    var a = b * 24 * 60 * 60 * 1000
    var e = new Date()
    e.setTime(e.getTime() + a)
    var f = b ? '; expires=' + e.toGMTString() : ''
    var c = ';path=/'
    document.cookie = d + '=' + escape(g) + f + c
  },
  del: function (c) {
    value = ''
    expires = '0'
    var a = expires * 24 * 60 * 60 * 1000
    var d = new Date()
    d.setTime(d.getTime() + a)
    var e = expires ? '; expires=' + d.toGMTString() : ''
    var b = ';path=/'
    document.cookie = c + '=' + escape(value) + e + b
  }
}
</script>

<style scoped lang="stylus">
bg(str)
  background url(str) no-repeat center 0 / 100% 100%
.item-Q5
  .top-btn
    display none
  p
    word-break break-all
  .main-top
    background #f4f4f4
    width 100%
    padding 2.6259rem 0 0.9259rem
    text-align left
    .fun-ava
      width 90%
      height 5.6rem
      overflow hidden
      img
        width 100%
        height 100%
    .fun-tit
      color #373737
      font-size 0.4444rem
      font-weight bold
      margin-bottom 0.4rem
      height 2rem
      overflow hidden
      -webkit-line-clamp 3
      display -webkit-box
      -webkit-box-orient vertical
    .fun-look-m
      color #373737
      font-size 0.2592rem
      margin 0 0 0.1rem 0
      display none
      p
        display inline-block
        vertical-align middle
      i
        width 0.2962rem
        height 0.1851rem
        display inline-block
        margin 0 0.2592rem 0 0
        bg '../../assets/images/Q/Q5/eye-icon.png'
        vertical-align middle
    .fun-time
      color #373737
      font-size 0.2592rem
      margin 0 0 0.1rem 0
      display flex
      p
        display inline-block
        vertical-align middle
        width 94%
      i
        width 0.2592rem
        height 0.2592rem
        display inline-block
        margin 0 0.2592rem 0 0
        bg '../../assets/images/Q/Q5/time-icon.png'
        vertical-align middle
        position relative
        top 0.04rem
    .fun-site
      color #373737
      font-size 0.2592rem
      display flex
      p
        display inline-block
        vertical-align middle
        width 94%
      i
        display inline-block
        width 0.2592rem
        height 0.2962rem
        margin 0 0.2592rem 0 0
        bg '../../assets/images/Q/Q5/site-icon.png'
        vertical-align middle
    .m-show-map
      padding 0.1rem 0
      text-align right
      margin 0 0.4rem
      display none
      .btn
        color #7f7f7f
        font-size 0.3703rem
        display inline-block
        cursor pointer
        &::before
          content ''
          display inline-block
          width 0.2592rem
          height 0.3518rem
          bg '../../assets/images/Q/Q5/map-icon.png'
          vertical-align middle
          margin 0 0.2rem 0 0
    .fun-txs
      display flex
      margin 0.54rem 0 0 0
      .fun-tx
        width 0.7222rem
        height 0.7222rem
        img
          width 100%
          height 100%
          object-fit cover
      .ava-fra
        display flex
        justify-content center
        align-items end
        flex-flow column
        margin 0 0 0 0.2rem
        .fun-name
          color #373737
          font-size 0.2592rem
        .concern-btn
          padding 0.01rem 0.2rem
          background #a5cd39
          color #fff
          font-size 0.2592rem
          border none
          outline none
          line-height normal
          cursor pointer
    .ava-btns
      margin 0.8rem 0 0 0
      button
        color #fff
        font-size 0.2962rem
        width 3.3148rem
        height 0.8333rem
        border none
        outline none
        cursor pointer
        line-height 0.8333rem
        &.join-btn
          background #a5cd39
          margin 0 0.2rem 0 0
          display inline-block
          vertical-align bottom
        &.collect-btn
          i
            width 0.3148rem
            height 0.2962rem
            bg '../../assets/images/Q/Q5/heart.png'
            display inline-block
            vertical-align middle
            margin 0 0.2rem 0 0
            &.act
              width 0.3148rem
              height 0.2962rem
              bg '../../assets/images/Q/Q5/heart-act.png'
              display inline-block
              vertical-align middle
              margin 0 0.2rem 0 0
          background #fff
          color #515555
          border 0.0185rem solid #c2c2c2
          display inline-flex
          justify-content center
          align-items center
    .fun-look-share
      margin 0.8rem 0 0 0
      .look
        color #373737
        font-size 0.2592rem
        display inline-block
        vertical-align middle
        &::before
          content ''
          display inline-block
          width 0.2962rem
          height 0.1851rem
          bg '../../assets/images/Q/Q5/eye-icon.png'
          vertical-align middle
          margin 0 0.2rem 0 0
      .share
        color #373737
        font-size 0.2592rem
        display inline-block
        vertical-align middle
        margin 0 0.5rem 0 0.5rem
      .social-share
        vertical-align middle
        display inline-block
  .main-mid
    background #fff
    padding 0.9259rem 0 1rem 0
    text-align left
    .w
      .fun-cnt
        width 100%
        color #464646
        font-size 0.4444rem
        padding 0.2rem 0
        border-bottom 1px solid #d3d3d3
        &.c2
          &::before
            bg '../../assets/images/Q/Q5/mid-icon2.png'
        &.c3
          &::before
            width 0.3518rem
            height 0.4259rem
            bg '../../assets/images/Q/Q5/mid-icon3.png'
          + .v-map
            width 100%
            height 6rem
        &.c4
          &::before
            width 0.3333rem
            height 0.4074rem
            bg '../../assets/images/Q/Q5/mid-icon4-m.png'
        &::before
          content ''
          display inline-block
          width 0.4074rem
          height 0.4074rem
          bg '../../assets/images/Q/Q5/mid-icon1.png'
          vertical-align middle
          position relative
          top -0.04rem
          margin 0 0.2rem 0 0
      .fun-cnt2
        padding 0.4rem 0 0 0
        img
          display block
          max-width 100%
          margin 0.4rem auto
      .p-textarea
        width 100%
        height 2.1rem
        background #f5f5f5
        padding 0.2rem
        resize none
        border none
        outline none
        margin 0.4rem 0 0 0
      .sub
        text-align right
        margin 0.4rem 0 0 0
        .submit-btn
          background #a5cd39
          padding 0.15rem 1.1rem
          border none
          outline none
          cursor pointer
          color #fff
          font-size 0.2962rem
      .p-ul
        width 100%
        padding 0.5rem 0 0 0
        li
          border-top 1px solid #d3d3d3
          padding 0.4rem 0
          width 100%
          display flex
          flex-wrap wrap
          position relative
          &:last-child
            border-bottom 1px solid #d3d3d3
          .p-tx
            width 1.1296rem
            height 1.1296rem
            img
              width 100%
              height 100%
              object-fit cover
          .p-right
            max-width 91%
            padding 0 0 0 0.4rem
            .p-name
              color #8d8d8d
              font-size 0.2962rem
              font-weight bold
              margin 0 0 0.2rem 0
            .p-cnt
              color #8d8d8d
              font-size 0.2592rem
            .zan-num
              position absolute
              color #8d8d8d
              bottom 0.1rem
              right 0.1rem
              span
                display inline-block
                width 0.2592rem
                height 0.2592rem
                bg '../../assets/images/Q/Q5/zan-icon.png'
                vertical-align unset
                margin 0 0.1rem 0 0
                cursor pointer
      .z-fra-m
        display none
      .z-intor
        width 100%
        background #f5f5f5
        margin-bottom 0.5rem
        .z-intor2
          padding 0.2rem 0.3rem 0.1rem
          .intor-top
            display flex
            align-items center
            img
              display block
              width 1.1851rem
              height 1.1851rem
            .intor-right
              display flex
              flex-flow column
              justify-content center
              margin 0 0 0 0.2rem
              .intor-cnt1
                color #464646
                font-size 0.2592rem
              .intor-cnt2
                color #464646
                font-size 0.4444rem
                word-break break-word
          .intor-des
            font-size 0.2592rem
            padding 0.4rem 0 0 0
          .intor-a
            padding 0.8rem 0 0 0
            li
              border-top 1px solid #a5cd39
              padding 0.3rem 0
              font-size 0.2592rem
              position relative
              &::before
                content ''
                position absolute
                width 0.1111rem
                height 0.2037rem
                bg '../../assets/images/Q/Q5/sqr.png'
                left 0
                top 50%
                margin-top -(@height / 2)
              a
                color #8d8d8d
                display block
                padding 0 0 0 0.3rem
        .gopage
          display block
          width 100%
          background #eaeaea
          padding 0.2rem 0.2rem 0.2rem 0
          text-align right
          color #464646
          font-size 0.2592rem
  .fun-swiper
    background #f4f4f4
    padding 0.9259rem 0
    .w
      .main-funActive
        text-align left
        .buzz-funActive
          padding-bottom 0.5rem
          .funActive-title
            position relative
            .title-funactive
              display inline-block
            .title-funactive-m
              display none
              max-width 5rem
            .fun-btn
              position absolute
              bottom 0
              right 0
              button
                color #464646
                font-size 0.25rem
                padding 0.15rem 0.5rem
                background #f0f0f0
                border 1px solid #BFDA5D
                cursor pointer
                transition all 0.3s ease-out 0s
                &:hover
                  color #fff
                  background #BFDA5D
              .fbtn-active
                // background: #BFDA5D;
          .funActive-card
            margin-top 0.5rem
            margin-right -0.35rem
            .small
              display inline-block
              width 100%
            .fcard-item
              position relative
              display inline-block
              background #fff
              margin-right 0.3rem
              .item-img
                border-radius 0
                transition all 0.3s ease-out 0s
                cursor pointer
                display block
                &:hover
                  transform scale(1.1)
              .fcard-content
                padding 0 0.4rem
                .fcard-txt
                  position relative
                  color #474747
                  font-size 0.3rem
                  margin-left 0.15rem
                  height 0.8rem
                  display flex
                  align-items center
                  p
                    padding-left 0.4rem
                    position relative
                    display: -webkit-box;
                    -webkit-box-orient: vertical;
                    -webkit-line-clamp: 2;
                    overflow: hidden;
                    &:before
                      content ''
                      position absolute
                      top 10%
                      left 0
                      height 80%
                      border-right 3px solid #A6CE36
                .fcard-icon
                  color #d3d3d3
                  font-size 0.25rem
                  margin-top 0.2rem
                  margin-bottom 0.1rem
                  p
                    i
                      color #BFDA5D
                      font-size 0.3rem
                      margin-right 0.3rem
                .fcard-foot
                  display flex
                  align-items center
                  padding 0.14rem 0
                  border-top 1px solid #d3d3d3
                  img, span
                    float left
                  span
                    margin-left 0.3rem
    .swipe-m-btn
      display none
      a
        display inline-block
        width 50%
        color #fff
        font-size 0.3703rem
        padding 0.2rem 0
        background #a5cd39
        text-align center
      .btn1
        border 0.0185rem solid #a5cd39
      .btn2
        background #fff
        border 0.0185rem solid #a5cd39
        color #464646
    // .arrow-btn
    // border none
    // outline 0
    // padding 0
    // margin 0
    // height 36px
    // width 36px
    // cursor pointer
    // -webkit-transition 0.3s
    // transition 0.3s
    // border-radius 50%
    // background-color rgba(31, 45, 61, 0.11)
    // color #FFF
    // position absolute
    // top 50%
    // z-index 10
    // -webkit-transform translateY(-50%)
    // transform translateY(-50%)
    // text-align center
    // font-size 12px
    // &.prev-btn
    // left -143px
    // &.next-btn
    // right -143px
    // i
    // ::before
    // content '\E6E0'
    // i
    // font-family element-icons !important
    // speak none
    // font-style normal
    // font-weight 400
    // font-variant normal
    // text-transform none
    // line-height 1
    // vertical-align baseline
    // display inline-block
    // -webkit-font-smoothing antialiased
    // -moz-osx-font-smoothing grayscale
    // cursor pointer
    // &::before
    // content '\E6DE'
    .over-hidden
      overflow hidden
      margin-bottom 0.1rem
      height 3.7rem
      img
        width 100%
        height 100%
        border-radius 0
.mask
  position fixed
  width 100%
  height 100%
  background rgba(0, 0, 0, 0.5)
  z-index 999
  top 0
  left 0
.tc-fra
  position fixed
  width 100%
  height 100%
  display flex
  align-items center
  justify-content center
  z-index 999
  top 0
  left 0
.tc
  text-align left
  min-width 9.8888rem
  width 9.8888rem
  padding 0.4rem 0.4rem
  z-index 999
  background #fff
  .tit
    color #464646
    font-size 0.4444rem
    padding 0 0.6rem
    border-bottom 1px solid #d3d3d3
    font-weight bold
  .der
    color #8d8d8d
    font-size 0.2962rem
    padding 0.6rem 1rem 0.4rem 1rem
  .btns
    text-align center
    margin-bottom 0.4rem
    a
      width 3.8148rem
      height 0.8333rem
      display inline-block
      line-height 0.8333rem
      font-size 0.2962rem
    .btn1
      color #515555
      background #fff
      border 0.0185rem solid #d3d3d3
      margin 0 0.1rem 0 0
    .btn2
      color #fff
      background #a5cd39
      border 0.0185rem solid #a5cd39
      margin 0 0 0 0.1rem
  .input-form
    padding 0 0.6rem 0.8rem 0.6rem
    max-height 10rem
    overflow auto
    margin-bottom 0.6rem
    p
      color #8d8d8d
      font-size 0.2962rem
      margin 0.4rem 0 0 0
      &:first-child
        margin 0
      span
        color #ff4800
        display inline-block
        vertical-align sub
    .input
      width 7.8703rem
      height 0.7407rem
      background #f7f9fa
      border 0.0185rem solid #d1d1d1
      outline none
      padding 0.2rem
  &.sign-form
    .tit
      color #00b6cd
    .qr
      display block
      margin 0 auto 0
    .code-num
      color #00b6cd
      font-size 0.4444rem
      text-align center
      padding 0.2rem .6rem 0.4rem
      word-break break-word
  &.sign-fun
    .der
      padding 0.6rem 0.6rem 0.4rem 0.6rem
  &.sign-prov-suc
    .prov-cnt
      display flex
      justify-content center
      align-items center
      padding 0.4rem 0
      img
        padding 0 0.8rem
      p
        color #8d8d8d
        font-size 0.2962rem
    .btn1
      margin 0
  &.sign-prov
    .check-box
      padding 0.8rem 0.6rem 0 0.6rem
      .check-d
        cursor pointer
        display inline-block
        padding 0 1rem 0 0
        .check-i
          display inline-block
          vertical-align middle
          width 0.2592rem
          height 0.2592rem
          border-radius 50%
          border 0.0185rem solid #8e8e8e
          position relative
          margin 0 0.2rem 0 0
          transition all 0.2s
          &.select
            border 0.0185rem solid #00b4d7
            transition all 0.2s
            &::before
              content ''
              width 0.1481rem
              height 0.1481rem
              border-radius 50%
              position absolute
              top 50%
              left 50%
              margin -(@height / 2) 0 0 0 - (@width / 2)
              background #00b4d7
        p
          color #8d8d8d
          font-size 0.2962rem
          display inline-block
          vertical-align middle
    .input-form
      padding 0.6rem 0.6rem 0.8rem 0.6rem
      .cnt-input
        .input
          width 5.52rem
        .sub-code
          width 2.3333rem
          height 0.7407rem
          background #a5cd39
          border none
          outline none
          text-align center
          line-height 0.7407rem
          color #fff
          font-size 0.2962rem
          display inline-block
          vertical-align bottom
    .agree
      padding 0 0 0.6rem 0.6rem
      font-size 0.2222rem
      color #8d8d8d
      a
        color #00b6cd
    .el-checkbox__input.is-checked+.el-checkbox__label
      color #8d8d8d
    .btn2
      width 4.36rem
.m-mapshow
  position fixed
  width 100%
  height 100%
  background rgba(0, 0, 0, 0.5)
  top 0
  left 0
  z-index 9999999999
  .back
    position fixed
    width 100%
    background #fff
    text-align left
    padding 0.6rem 0.4rem
    z-index 10
    .tit
      color #464646
      font-size 0.4444rem
      display inline-block
      &::before
        content ''
        width 0.2592rem
        height 0.3518rem
        bg '../../assets/images/Q/Q5/map-icon.png'
        display inline-block
        vertical-align middle
        margin 0 0.2rem 0 0
    .back-btn
      color #464646
      font-size 0.4444rem
      background #fff
      border 0.0185rem solid #464646
      padding 0.2rem 1.2rem
      position absolute
      top 50%
      transform translate(0, -50%)
      right 0.4rem
      outline none
@media screen and (max-width: 990px)
  .item-Q5
    background #f4f4f4
    .top-btn
      display block
    .submit-btn
      font-size 0.4444rem !important
    .main-top
      padding 1.1rem 0 0
      margin 0 0 0.3rem 0
      background #fff
      .fun-ava
        width 100%
        img
          width 100%
          height 100%
          object-fit cover
      .fun-tit
        padding 0.2rem 0.4rem 0 0.4rem
      .fun-time, .fun-site
        border-bottom 0.0185rem solid #c0c0c0
        margin 0 0.4rem
        padding 0.1rem 0
        color #7f7f7f
        font-size 0.3703rem
        i
          position relative
          top 0.1rem
      .fun-txs, .ava-btns, .fun-look-share
        display none
      .fun-look-m
        display block
        border-bottom 0.0185rem solid #c0c0c0
        margin 0 0.4rem
        padding 0.1rem 0
        color #7f7f7f
        font-size 0.3703rem
        position relative
      .m-show-map
        display block
        position absolute
        right 0
        top 0
      .btns.top-btn
        margin 0.4rem
        a
          display inline-block
          width 49%
          color #fff
          font-size 0.4444rem
          line-height 1.2037rem
          background #a5cd39
          text-align center
          height 1.2037rem
        .btn1
          border 0.0185rem solid #a5cd39
          margin 0 1% 0 0
        .btn2
          background #fff
          border 0.0185rem solid #a5cd39
          color #464646
          margin 0 0 0 1%
    .main-mid
      padding 0.2rem 0.4rem 0.6rem 0.4rem
      overflow-x hidden
      .w
        .z-fra
          display none
        .z-fra-m
          display block
          .z-intor
            .z-intor2
              padding 0.2rem 0 0.1rem
              .intor-a
                padding 0.4rem 0 0 0
                li
                  font-size 0.3703rem
                  border-top 0.0185rem solid #c0c0c0
                  &:last-child
                    border-bottom 0.0185rem solid #c0c0c0
            background #fff
            .intor-des
              font-size 0.3703rem
              color #7f7f7f
            .btns
              padding 0.4rem 0 0 0
              a
                display inline-block
                width 100%
                height 1.2037rem
                vertical-align middle
                text-align center
                line-height 1.2037rem
                color #fff
                font-size 0.4444rem
              .btn1
                background #a5cd39
                border 0.0185rem solid #a5cd39
                margin 0 0 0 0
              .btn2
                color #464646
                border 0.0185rem solid #a5cd39
                margin .2rem 0 0 0
          &::after
            content ''
            display inline-block
            height 0.3rem
            width 114%
            margin-left -0.4rem
            background #f4f4f4
        .fun-cnt
          border none
        .fun-cnt2
          overflow inherit
          p
            font-size 0.3703rem
          &::after
            content ''
            display inline-block
            height 0.3rem
            width 114%
            margin-left -0.4rem
            background #f4f4f4
            margin-top .4rem
        .p-ul
          li
            padding 0.4rem 0 0.8rem
            .p-name
              font-size 0.3333rem !important
            .p-cnt
              font-size 0.3333rem !important
            .p-right
              max-width 86%
            .zan-num
              font-size 0.3333rem !important
    .fun-swiper
      padding 0.9259rem 0.4rem 0.4rem
      .w
        .main-funActive
          .buzz-funActive
            .funActive-title
              .title-funactive
                display none
              .title-funactive-m
                display inline-block
      .fcard-item .fcard-content .fcard-icon p
        width 3rem
        overflow hidden
        text-overflow ellipsis
        white-space nowrap
      .fcard-foot
        padding 0 !important
        border-top none !important
        img
          position absolute
          right 3%
          bottom 18%
          width 0.75rem
          float left
  .mask
    background #fff
  .tc
    text-align center
    min-width 100%
    padding 0.4rem 1rem
    .tit
      padding-bottom 0.2rem
      font-size 0.5185rem
    .der
      color #373737
      padding 0.6rem 0 0.4rem 0
      font-size 0.3333rem
    .btns
      a
        width 5.9074rem
        height 1.2037rem
        font-size 0.4444rem
        line-height 1.2037rem
        display block
      .btn1
        margin 0 auto 0
      .btn2
        margin 0.4rem auto 0
    &.sign-form
      .tit
        color #373737
      .code-num
        padding 0.2rem 0 0.8rem
        span
          display block
    &.sign-fun
      .der
        color #8d8d8d
        text-align left
        padding 0.6rem 0 0.8rem
        font-size 0.3703rem
      .input-form
        text-align left
        padding 0 0 1.6rem
        p.i-p
          font-size 0.3703rem
        .input
          width 100%
          font-size 0.3703rem
    &.sign-prov
      .check-box
        text-align left
        padding 0.8rem 0 0
        .check-d
          p
            font-size 0.3333rem
      .input-form
        text-align left
        padding 0.6rem 0 0.8rem
        .input
          width 100%
          font-size 0.3333rem
        p.i-p
          font-size 0.3333rem
          margin 0.6rem 0 0 0
        .cnt-input
          width 100%
          .input
            width 70%
          .sub-code
            width 30%
            font-size 0.3333rem
      .btns
        .btn2
          width 5.9074rem
      .agree
        display flex
        font-size 0.3333rem
        padding 0 0 1.6rem 0
    &.sign-prov-suc
      .prov-cnt
        flex-wrap wrap
        padding 0.4rem 0 1.6rem
        img
          max-width 2.8rem
          padding 1rem 0.8rem 0.4rem
        p
          font-size 0.3703rem
@media screen and (max-width: 760px)
  .item-Q5
    .display-none
      display none !important
    .item-img
      border-radius 0 !important
    .fun-swiper
      .w
        .main-funActive
          .buzz-funActive
            .funActive-card
              .fcard-item
                display flex
                justify-content center
                align-items center
                margin-top 0.3rem
                width 95%
                .over-hidden
                  display grid
                  height 2.5rem
                  margin 0
      .swipe-m-btn
        display block
</style>
<style lang="stylus">
.item-Q5{
  overflow hidden
  .agree{
    .el-checkbox__label{
      color #8d8d8d
    }
  }
  .map{
    width 100%
    height 100%
  }
  .fun-cnt2{
    img{
      display block
      max-width 100%
      margin 0.4rem auto
    }
  }
  .side-icon{
    width 0.3518rem
    height 0.4259rem
    background url(../../assets/images/Q/Q5/mid-icon3.png) no-repeat center 0 /100% 100%
    animation: jump .5s infinite alternate;
    position absolute
  }
  .tc{
    .el-form-item__label{
      color #8d8d8d
      font-size 0.2962rem
    }
    .el-form-item{
      margin-bottom .3rem
      &.poneinput{
        .el-input-group__prepend{
          padding 0 .2rem
          border-radius 0
        }
        .areacode{
          width 28%
        }
        .phone{
          width 72%
          padding 0 0 0 .2rem
        }
      }
      .el-form-item__content{
        margin 0 !important
        .el-input__inner{
          border-radius 0
          border 0.0185rem solid #d1d1d1
          background #f7f9fa
        }
      }
      &:first-child{
        position relative
        .sub-code{
          width 2.3333rem
          height 40px
          background #a5cd39
          border none
          outline none
          text-align center
          line-height 40px
          color #fff
          font-size 0.2962rem
          display inline-block
          vertical-align bottom
          position absolute
          right 0
          cursor pointer
          transition all .2s
          &.disablebtn{
            cursor no-drop
            background #b3e19d
          }
        }
      }
    }
  }
@media screen and (max-width: 990px){
  .agree{
    .el-checkbox__label{
      font-size 0.3333rem
    }
  }
}
  @keyframes jump
  {
    from {
      transform:translateY(0);
      }
    to {
      transform:translateY(20%);
      }
  }
  .el-date-editor.el-input{
    width 100%
    border-radius 0
    background #f7f9fa
  }
  .el-input__inner,.el-textarea__inner{
    border-radius 0
    border 0.0185rem solid #d1d1d1
    border-radius 0
    background #f7f9fa
  }
  .el-select{
    width 100%
  }
  .moreform{
    >div{
      padding .3rem 0 0 0
    }
  }
}
</style>
